
import {
  Employee,
  Location,
  Concept as ConceptService,
  Integrations as IntegrationService, Location as LocationService, Subscription as SubscriptionApi
} from "@/services/SOLO";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { translations } from "@/mixins";
import { mapGetters } from "vuex";

// let Locations = new LocationService();

interface DeliveryZone {
  coordinates: number[][];
  id: string;
  label: string;
  deliveryCharge: number | null;
  minimumOrderAmount: number | null;
}

@Component({
  mixins: [translations],
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      getLocale: 'app/getLocale',
    }),
  },
})

export default class LocationDeliveryZone extends Vue {
  @Prop() locationId!: string;
  $notify: any;
  getConceptSettings!: any;
  private isOverrideDeliveryCharges: boolean = false;
  private deliveryZones: DeliveryZone[] = [];
  private isSaving: boolean = false;
  private saveButtonText: string = '';
  translate!: Function;

  mounted() {
    this.saveButtonText = this.translate("SAVE") as string;
    console.log("created location: ", this.locationId);
    this.fetchDeliveryZones();
  }

async fetchDeliveryZones(page = 1, size = 15) {
  try {
    const response = await Location.deliveryAreas(this.locationId, page, size);
    const deliveryAreas = response.data.data;
    console.log('Delivery Areas Data: ', deliveryAreas);
    const zones = deliveryAreas.map((area: any) => {
      const areaId = area.id;
      // console.log('Coordinates: ', area.attributes.coordinates)

      return {
        id: areaId,
        label: area.attributes.label,
        deliveryCharge: area.attributes['delivery-charge'] || null,
        minimumOrderAmount: area.attributes['minimum-order-amount'] || null,
        coordinates: area.attributes.coordinates,
      };
    });

    // this.deliveryZones = zones;
    this.deliveryZones = [...this.deliveryZones, ...zones];

    if (
        response.data.meta.pagination.current_page <
        response.data.meta.pagination.total_pages
    ) {
      this.fetchDeliveryZones(response.data.meta.pagination.current_page + 1);
    }
  } catch (error) {
    console.error('Error fetching delivery zones:', error);
  }
}

async saveDeliveryZoneSettings() {
  // Change the button text to "SAVING"
  this.saveButtonText = this.translate("SAVING");

  // Disable the button during the save operation
  this.isSaving = true;

  try {
    // Retrieve the updated delivery zones data
    const updatedDeliveryZones: DeliveryZone[] = this.deliveryZones.map((zone) => ({
      ...zone,
      deliveryCharge: Number(zone.deliveryCharge),
      minimumOrderAmount: Number(zone.minimumOrderAmount),
    }));


    // Update each delivery zone
    for (const zone of updatedDeliveryZones) {
      let coordinates = zone.coordinates;

      if (coordinates[0][0] != coordinates[coordinates.length-1][0] && coordinates[0][1] != coordinates[coordinates.length-1][1]) {
        coordinates.push(coordinates[0]);
      }

      // Prepare the payload for the update
      const payload = {
        label: zone.label,
        'delivery-charge': zone.deliveryCharge,
        'minimum-order-amount': zone.minimumOrderAmount,
        coordinates: coordinates,
      };

      // Make the API call to update the delivery area
      await Location.updateDeliveryArea(
        payload, 
        this.locationId, 
        zone.id);
    }
    
    // Show success toast with translated message
    this.$notify({
      title: this.translate("RECORDS SAVED!"),
      verticalAlign: "bottom",
      horizontalAlign: "left",
      message: this.translate("Delivery Zone settings saved successfully"),
      type: "success",
      icon: "fas fa-check",
    });

    // Reset the checkbox state to false
    this.isOverrideDeliveryCharges = false;
  } catch (error) {
    console.error("Error saving delivery zone settings:", error);
  } finally {
    // Enable the button after the save operation is completed
    this.isSaving = false;

    // Restore the original button text
    this.saveButtonText = this.translate("SAVE");
  }
}

 private cancelPopup() {
    this.$emit("location:cancel");
  }


  get deliveryZoneFields(): any[] {
    const currencyCode = this.currencyCode;
    return [
      { key: "label", label: this.translate("Delivery Zone") },
      { key: "delivery-charge", label: `${this.translate("Delivery Charge")} (${currencyCode})` },
      { key: "minimum-order-amount", label: `${this.translate("Minimum Order")} (${currencyCode})` },
    ];
  }

  get currencyCode(): string {
    if (this.getConceptSettings && this.getConceptSettings.attributes) {
      return this.getConceptSettings.attributes['currency-code'];
    }
    return ''; // Return an empty string or a default value if the object is undefined or properties are missing
  }

  @Watch('isOverrideDeliveryCharges')
   onOverrideDeliveryChargesChange(newVal: boolean): void {
    if (newVal) {
      // Checkbox is checked, set the value of isOverrideDeliveryCharges to true
      this.isOverrideDeliveryCharges = true;
      console.log('override: ', this.isOverrideDeliveryCharges);
    }
  }
}
